import { settings, kit } from './stores';

export function registerComponent(Component) {
  return opts => {
    const cfg = settings.getValue();
    opts = opts || {};

    const ret = new Component.default({
      target: opts.target || cfg.defaultContainer || document.body,
      props: opts.props || {},
    });

    ret.$on('destroy', () => {
      ret.$destroy();
    });

    ret.promise = new Promise((resolve, reject) => {
      ret.$on('destroy', e => {
        resolve(e && e.detail);
      });
    });

    return ret;
  };
}

export function init(CmKit) {
  kit.set(CmKit);

  class CdlmKitComponent extends HTMLElement {
    connected = false;
    ui = null;
    listeningEvents = new Map();
    subs = [];

    constructor() {
      super();
    }

    addEventListener(event, fn, opts) {
      super.addEventListener(event, fn, opts);
      this.listeningEvents.set(event, true);
      if (this.ui) {
        this.subscribeToUIEvent(event);
      }
    }

    connectedCallback() {
      if (this.connected) {
        return;
      }

      this.connected = true;
      this.render();
      this.observeChanges();
    }

    observeChanges() {
      const observer = new MutationObserver(mutations => {
        const attrs = this.getAttributes(this);

        if (this.ui) {
          this.ui.$set(attrs);
        }
      });

      observer.observe(this, {
        attributes: true,
      });

      this.subs.push(() => {
        observer.disconnect();
      });
    }

    forceUpdate() {
      const attrs = this.getAttributes(this);
      if (this.ui) {
        this.ui.$set(attrs);
      }
    }

    render() {
      setTimeout(() => {
        const attrs = this.getAttributes(this);

        if (attrs.component && CmKit[attrs.component]) {
          let c = attrs.component;
          delete attrs.component;
          this.ui = CmKit[c]({ target: this, props: attrs });
          this.ui.parent = this;

          for (let [key] of this.listeningEvents) {
            this.subscribeToUIEvent(key);
          }
        }
      }, 0);
    }

    disconnectedCallback() {
      this.subs.forEach(off => {
        try {
          off();
        } catch (e) {}
      });
      try {
        this.ui.$destroy();
      } catch (e) {}
    }

    customSetAttributes(attrs) {
      this._attrs = attrs;
      this.forceUpdate();
    }

    getAttributes(el, prefix = null) {
      let obj;

      if (el._attrs) {
        obj = {};
        for (let key in el._attrs) {
          obj[key] = el._attrs[key];
        }
        obj.component = el.getAttribute('component');
        return obj;
      }

      // turn the nodelist into an array
      return Array.prototype.slice
        .call(el.attributes)
        .reduce((acc, attributeNode) => {
          // turn the array into an object
          let name = attributeNode.nodeName;
          let value = attributeNode.nodeValue.trim();
          if (prefix) {
            if (name.startsWith(prefix)) {
              name = name.replace(prefix, '');
            } else {
              return acc;
            }
          }

          // parse objects or arrays
          try {
            if (
              value.charAt(0) === '{' &&
              value.charAt(value.length - 1) === '}'
            ) {
              value = JSON.parse(value);
            } else if (
              value.charAt(0) === '[' &&
              value.charAt(value.length - 1) === ']'
            ) {
              value = JSON.parse(value);
            }
          } catch (e) {}

          acc[name] = value;
          return acc;
        }, {});
    }

    subscribeToUIEvent(key) {
      this.ui.$on(key, e => {
        this.dispatchEvent(
          new CustomEvent(key, { target: this, detail: e.detail })
        );
      });
    }
  }

  customElements.define('cm-kit', CdlmKitComponent);
}
