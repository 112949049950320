<script>
  import { createEventDispatcher } from 'svelte';

  import Password from './Types/Password.svelte';
  import PhoneInput from './Types/PhoneInput.svelte';
  import CodeInput from './Types/CodeInput.svelte';
  import MessageTextArea from './Types/MessageTextArea.svelte';

  export let type = 'password';
  export let placeholder = 'Password';
  export let value = '';
  export let showText = null;
  export let input_count = 3;
  export let rows = 1;

  const dispatch = createEventDispatcher();
</script>

<style src="./Input.scss" global>
</style>

{#if type === 'password'}
  <Password {value} {dispatch} {placeholder} {showText} />
{/if}

{#if type === 'phoneInput'}
  <PhoneInput />
{/if}

{#if type === 'codeInput'}
  <CodeInput {input_count} />
{/if}

{#if type === 'messageTextArea'}
  <MessageTextArea {value} {placeholder} {rows} {dispatch} />
{/if}
