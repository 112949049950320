<script>
  import _ from 'lodash';

  export let dispatch = () => {};
  let value = '';
  let formattedPhone = '';

  function keyevent(e) {
    if (e.key === 'Backspace' && value.length > 0) {
      value = value.slice(0, -1);
    } else if (_.isFinite(_.toNumber(e.key)) && value.length < 10) {
      value = value.concat(e.key);
    }
    formatDisplayedPhoneNumber();
  }
  function formatDisplayedPhoneNumber() {
    formattedPhone = '';
    const areaCode = value.substr(0, 3),
      cityNumber = value.substr(3, 3),
      phoneLine = value.substr(6, 4);

    if (areaCode) {
      formattedPhone = '('.concat(areaCode);
    } else {
      formattedPhone = '';
    }
    if (areaCode.length === 3) {
      formattedPhone = formattedPhone.concat(')');
    }
    if (cityNumber) {
      formattedPhone = formattedPhone.concat(cityNumber);
    }
    if (cityNumber.length === 3) {
      formattedPhone = formattedPhone.concat('-');
    }
    if (phoneLine) {
      formattedPhone = formattedPhone.concat(phoneLine);
    }
  }
</script>

<div class="cm-Grid">
  <div class="cm-Grid-row">
    <div style="width:35px">
      <input
        class="cm-Input cm-Input--flat cm-Input--flat--verificationPhone"
        name="prefix"
        value="+ 1"
        type="text" />
    </div>
    -
    <div class="cm-Width-expand">
      <input
        on:keyup={keyevent}
        class="cm-Input cm-Input--flat cm-Input--flat--verificationPhone"
        name="phoneNumber"
        value={formattedPhone}
        on:input={() => {
          setTimeout(() => {
            dispatch('value', { value: value });
          });
        }}
        on:change={() => {
          setTimeout(() => {
            dispatch('value', { value: value });
          });
        }}
        type="text" />
    </div>
  </div>
</div>
