<script>
  import { createEventDispatcher } from 'svelte';
  import _ from 'lodash';

  export let tabs = [];
  export let active_tab = 0;

  const dispatch = createEventDispatcher();

  function findTabIndex(tab) {
    return _.findIndex(tabs, t => {
      return t.id === tab.id;
    });
  }
</script>

<style src="./SubNav.scss" global>
</style>

<div class="cm-Grid SubNav">
  <div class="cm-Grid-row">
    {#each tabs as tab, i}
      <div
        on:click={e => {
          active_tab = i;
          dispatch('change', { value: tab });
        }}
        class="SubNav-item">
        <div class="SubNav-label">{tab.label}</div>
        {#if String(active_tab) === String(findTabIndex(tab))}
          <div class="SubNav-activeIndicator" />
        {/if}
      </div>
    {/each}
  </div>
</div>
