<script>
  import _ from 'lodash';
  import { onMount } from 'svelte';
  export let dispatch = () => {};
  export let input_count = 4;
  let values = [];
  $: shortValue = values.map(d => d.fieldValue || '').join('');

  onMount(() => {
    let i = 0;
    values = [];
    _.range(input_count).forEach(r => {
      values.push({ fieldValue: '', id: i });
      i++;
    });
  });

  /*
  Testing
  123
  1234
  1
  123456
  */

  function keyevent(e, obj) {
    console.log(e, obj);
    const val = String(e.target.value || '');
    console.warn('value', val);
    // Make sure value exits
    e.target.value = val[0] || '';
    if (e.target.value) {
      let el = e.target;
      obj.fieldValue = val[0];
      for (let i = 1; i < val.length; i++) {
        el = el.nextElementSibling;
        if (el) {
          let nextObject = _.find(values, { id: obj.id + i });
          nextObject.fieldValue = val[i] || '';
          el.value = val[i] || '';
        } else {
          break;
        }
      }
      el.nextElementSibling.focus();
    }

    dispatch('value', {
      value: shortValue,
    });
  }
</script>

<div style="text-align:center">
  {#each values as v}
    <input
      on:input={e => keyevent(e, v)}
      class="cm-Input cm-Input--flat cm-Input--flat--digit"
      bind:value={v.fieldValue}
      type="tel" />
  {/each}
</div>
