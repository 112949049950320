<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { each } from 'svelte/internal';
  import { templater } from '../../actions/templater.js';
  import SlideOver from '../SlideOver/SlideOver.svelte';
  const dispatch = createEventDispatcher();

  export let templates = {
    button: null,
    cancel: null,
    title: null,
    header: null,
    subText: null,
  };
  export let buttons = [];
  export let title;

  let closed = false;

  function close(d) {
    closed = true;
    setTimeout(() => {
      dispatch('destroy', d);
    }, 500);
  }
</script>

<style src="./ActionSheet.scss" global>
</style>

<div class="cm-SlideOver cm-ActionSheet" class:is-closed={closed}>
  <div class="cm-SlideOver-overlay" />
  <div class="cm-SlideOver-body">
    <div class="cm-ActionSheet-title">
      <div use:templater={{ template: templates.title, data: {} }}>
        {#if title}
          <div class="cm-ActionSheet-titleText">
            {@html title}
          </div>
        {/if}
      </div>
    </div>
    <div class="cm-ActionSheet-buttons">
      {#each buttons as button}
        <div
          class="cm-ActionSheet-button"
          on:click={() => {
            try {
              if (button.handler) button.handler();
            } catch (e) {}
            close(button);
          }}>
          <div use:templater={{ template: templates.button, data: button }}>
            <div class="cm-ActionSheet-buttonTemplate">
              {#if button.icon}
                <div class="cm-ActionSheet-icon">
                  {@html button.icon}
                </div>
              {/if}
              <div
                class="cm-ActionSheet-text"
                cm-color={button.textColor || 'Neutral / Mine Shaft'}>
                {@html button.text}
                {#if button.subText}
                  <div
                    cm-font="Body / 12 Regular"
                    cm-color={button.subTextColor || 'Neutral / Storm Grey'}>
                    {@html button.subText}
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>
    <div
      class="ActionSheet-cancelButton"
      on:click={() => {
        close();
      }}>
      <div use:templater={{ template: templates.cancel, data: {} }}>
        <button
          class="cm-Button cm-Button--1 cm-Button--alternate cm-Width-1-1"
          cm-color="Neutral / Mine Shaft">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
